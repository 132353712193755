import React, {useEffect, useState} from 'react';
import {Button, CircularProgress} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        paddingTop: "50px"
    },
    subHeadingContainer: {
        textAlign:"center"
    },
    subHeading: {
        paddingBottom: "10px"
    },
    saveButtonContainer: {
        padding: "50px"
    },
    emptyList: {
        textAlign: 'center',
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#909a9b',
        margin: 20,
        position: 'relative'
    },
    emptyListIcon: {
        fontSize: '3em',
    },
    skillItemSelected: {
        padding: '20px',
        margin: '20px',
        border: 'solid',
        borderWidth: '2px',
        borderColor: '#6c078e',
        backgroundColor: '#6c078e',
        borderRadius: '10px',
        color: 'white',
        cursor: 'pointer',
        // textTransform: "capitalize",
        textAlign: "left"
    },
    skillItem: {
        padding: '20px',
        margin: '20px',
        borderRadius: '10px',
        border: 'solid',
        borderWidth: '2px',
        borderColor: '#9b989c',
        // backgroundColor: theme.custom.skillItemBackground,
        cursor: 'pointer',
        // textTransform: "capitalize",
        textAlign: "left"
    },
    skillRatingAvatar: {
        margin: "auto",
        fontSize: "30px",
        filter: "grayscale(100%)",
        textAlign: "center"
    },
    skillRatingAvatarSelected: {
        margin: "auto",
        fontSize: "40px",
        textAlign: "center"
    },
    skillRatingAvatarContainer: {
        margin: "10px",
        cursor: 'pointer'
    },
    levelTitle: {
        textAlign: 'center'
    },
    levelTitleSelected: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#6c078e'
    }
}))


export default function LevelsRubricSegment({survey, segment, response, saveResponse, getNextUrl}) {

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [categorisedSkills, setCategorisedSkills] = useState({})

    const navigate = useNavigate();

    useEffect(() => {
        const tempResponse = Object.assign({}, response)

        const localCategorisedSkills = {};

        Object.keys(survey.skillGroups).forEach(skillGroupId => {

            const skills = []
            Object.entries(survey.skillGroups[skillGroupId]).forEach(([skillId, skill]) => {
                skill.id = skillId
                skill.skillGroupId = skillGroupId

                if (!tempResponse.skillGroupsMeta) {
                    tempResponse.skillGroupsMeta = {};
                }
                if (!tempResponse.skillGroupsMeta[skillGroupId]) {
                    tempResponse.skillGroupsMeta[skillGroupId] = {};
                }

                if (!tempResponse.skillGroups) {
                    tempResponse.skillGroups = {};
                }
                if (!tempResponse.skillGroups[skillGroupId]) {
                    tempResponse.skillGroups[skillGroupId] = {};
                    tempResponse.skillGroupsMeta[skillGroupId] = {};
                }
                if (!tempResponse.skillGroups[skillGroupId][skillId]) {
                    tempResponse.skillGroups[skillGroupId][skillId] = {}
                }

                skill.isSelected = tempResponse.skillGroups[skillGroupId][skillId].isSelected ? tempResponse.skillGroups[skillGroupId][skillId].isSelected : false
                skills.push(skill)
            })

            skills.sort((a, b) => {
                return a.level - b.level
            })

            localCategorisedSkills[skillGroupId] = {
                skillGroupId: skillGroupId,
                title: survey.skillGroupsMeta[skillGroupId].title,
                skills: skills.slice(),
                joyRating: tempResponse.skillGroupsMeta[skillGroupId].joyRating ? tempResponse.skillGroupsMeta[skillGroupId].joyRating : 2
            };
        })

        setIsLoading(false)
        setCategorisedSkills(localCategorisedSkills)
    },[segment, survey, response]);

    const isUsing = (category, index) => {
        let localCategorisedSkills = Object.assign({}, categorisedSkills)

        for (let i = 0; i < localCategorisedSkills[category].skills.length; i++) {
            localCategorisedSkills[category].skills[i].isSelected = false
        }
        localCategorisedSkills[category].skills[index].isSelected = true;

        setCategorisedSkills(localCategorisedSkills)
    }

    function rateJoy(category, rating) {
        let localCategorisedSkills = Object.assign({}, categorisedSkills)
        localCategorisedSkills[category].joyRating = rating;
        setCategorisedSkills(localCategorisedSkills)
    }

    function buildSkillsList() {

        const skillsJsx = []
        Object.entries(categorisedSkills).forEach(([category, object]) => {
            skillsJsx.push(skillSelectionJsx(object.skillGroupId, object.title, object.skills, object.joyRating))
        })

        if (skillsJsx.length) {
            return skillsJsx;
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You can skip this step!</Typography>
            </div>
        )

    }

    const skillSelectionJsx = (skillGroupId, category, skills, currentRating) => {
        return  (
            <Grid key={category} item lg={12}>
                <div style={{border:'solid', borderRadius: '5px', borderWidth: '1px', margin: 10, marginBottom: 20, boxShadow: "5px 5px 5px 2px #888888"}}>
                    <Typography variant="h5" style={{paddingTop: '10px', paddingBottom: '20px', textAlign: 'center'}}><strong>{category}</strong></Typography>
                    <Grid container justify="center" alignItems="center" spacing={0}>
                        {skills.map((skill, index) => {
                            return (
                                <Grid item key={skill.title} lg={12/skills.length} onClick={() => isUsing(skillGroupId, index)}>
                                    <Typography variant="body1" className={skill.isSelected ? classes.levelTitleSelected : classes.levelTitle} >Level {index + 1}</Typography>
                                    <div className={skill.isSelected ? classes.skillItemSelected : classes.skillItem} >
                                        <Typography variant="body1">
                                            {skill.description}
                                        </Typography>
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            {/*<Typography variant="h6">{skill.title}</Typography>*/}
                            {/*<Divider/>*/}
                            <Typography variant="h6">How much do you enjoy doing this?</Typography>
                        </Grid>
                        <Grid  item xs={4}>
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid onClick={() => rateJoy(skillGroupId, 1)}  item xs={3} className={classes.skillRatingAvatarContainer}>
                                    <div className={currentRating === 1 ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                        <span role="img" aria-label="frustrated">😩</span>
                                    </div>
                                </Grid>
                                <Grid onClick={() => rateJoy(skillGroupId, 2)}  item xs={3} className={classes.skillRatingAvatarContainer}>
                                    <div className={currentRating === 2 ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                        <span role="img" aria-label="content">🙂</span>
                                    </div>
                                </Grid>
                                <Grid onClick={() => rateJoy(skillGroupId, 3)}  item xs={3} className={classes.skillRatingAvatarContainer}>
                                    <div className={currentRating === 3 ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                        <span role="img" aria-label="love">😍</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid>)

    }

    const save = async () => {
        setIsSaving(true)
        let tempResponse = Object.assign({}, response)

        Object.entries(categorisedSkills).forEach(([key, object]) => {

            if (!tempResponse.skillGroupsMeta) {
                tempResponse.skillGroupsMeta = {};
            }
            if (!tempResponse.skillGroupsMeta[object.skillGroupId]) {
                tempResponse.skillGroupsMeta[object.skillGroupId] = {};
            }

            tempResponse.skillGroupsMeta[object.skillGroupId].joyRating = object.joyRating;
            tempResponse.skillGroupsMeta[object.skillGroupId].title = object.title;

            for (const skill of object.skills) {
                tempResponse.skillGroups[skill.skillGroupId][skill.id].isSelected = skill.isSelected
                tempResponse.skillGroups[skill.skillGroupId][skill.id].title = skill.title
                tempResponse.skillGroups[skill.skillGroupId][skill.id].level = skill.level

                if (skill.isSelected) {
                    tempResponse.skillGroupsMeta[object.skillGroupId].level = skill.level;
                }
            }
        })

        try {
            await saveResponse(tempResponse);
            navigate(getNextUrl())
        } catch (e) {
            console.error(e)
            setIsSaving(false)
        }
    }

    const skillListJsx = buildSkillsList()

    return (
        <React.Fragment>
            {isLoading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <CircularProgress/>
                </Grid>
                :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography className={classes.subHeading} variant="h4">{segment.title}</Typography>
                            <Typography className={classes.subHeading} variant="h5">{segment.description}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center">
                                {skillListJsx}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={6} className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        {
                            isSaving ?
                                <CircularProgress/> :
                                <Button size="large" variant="contained" color="primary" onClick={save}>Next →</Button>
                        }
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}
