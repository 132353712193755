import React from 'react';
import {Button} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    subHeadingContainer: {
        textAlign:"center"
    },
    subHeading: {
        paddingBottom: "10px"
    },
    saveButtonContainer: {
        padding: "50px"
    }
}))


export default function SegmentNotFound({getNextUrl}) {

    const classes = useStyles()

    return (
        <React.Fragment>
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography className={classes.subHeading} variant="h4">Sorry, segment not found</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center">
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        <Button size="large" variant="contained" color="primary" onClick={getNextUrl}>Next →</Button>
                    </Grid>
                </React.Fragment>
        </React.Fragment>

    );
}
