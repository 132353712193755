import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import bookstack from "../static/bookstack.svg";
import Reward from "react-rewards";
import {makeStyles} from "@material-ui/core/styles";
import Logo from "./Logo";
import {Container} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    logo: {
        textAlign: "center",
        paddingTop: "25px"
    },
    paperContainer: {
        padding: "40px",
        marginTop: "25px"
    },
    subHeadingContainer: {
        textAlign:"center",
        padding: "25px"
    },
    saveButtonContainer: {
        padding: "50px"
    },
    callout: {
        color: theme.palette.primary.main
    }
}))

let reward;

export default function Complete() {

    const classes = useStyles();

    useEffect(() => {
        if (reward && reward.rewardMe) {
            setTimeout(() => {
                reward.rewardMe()
            }, 500)
        }
    }, [])

    return (
        <Container>
            <Grid container direction="row" justify="center" alignItems="center">
                <Logo/>
            </Grid>
                <Paper elevation={3} className={classes.paperContainer}>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={6}>
                            <Typography variant="h4">Thank you for taking the time to fill out your profile!</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Reward
                                ref={(ref) => { reward = ref }}
                                type='confetti'
                                config={{
                                    lifetime: 250,
                                    spread: 200,
                                    startVelocity: 55,
                                    elementCount: 250,
                                    springAnimation: false
                                }}><p></p>
                            </Reward>
                        </Grid>
                        <Grid item xs={4}>
                            <img src={bookstack} height={"250px"} alt={"Book Stack Woman"} />
                        </Grid>
                    </Grid>
                </Paper>
        </Container>
    );
}
