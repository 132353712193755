import React from 'react';
import {Container} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import bookstack from "../static/bookstack.svg";
import {makeStyles} from "@material-ui/core/styles";
import Logo from "./Logo";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    logo: {
        textAlign: "center",
        paddingTop: "25px"
    },
    paperContainer: {
        padding: "40px",
        marginTop: "25px"
    },
    subHeadingContainer: {
        textAlign:"center",
        padding: "25px"
    },
    saveButtonContainer: {
        padding: "50px"
    },
    callout: {
        color: theme.palette.primary.main
    }
}))


function NotFound() {

    const classes = useStyles();

    return (
            <Container>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Logo/>
                </Grid>
                <Paper elevation={3} className={classes.paperContainer}>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={6}>
                            <Typography variant="h4">Not found!</Typography>
                            <Typography variant="subtitle1">Sorry this survey can no longer be found here.</Typography>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={4}>
                            <img src={bookstack} height={"250px"} alt={"Book Stack Woman"} />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
    );
}

export default NotFound;
