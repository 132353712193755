import React, {useEffect, useState} from 'react';
import {Avatar, Button, CircularProgress, Divider, Slider, Tooltip} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    skillItem: {
        padding: '20px',
        margin: '20px',
        textTransform: "capitalize",
        textAlign: "center"
    },
    loadingContainer: {
        paddingTop: "50px"
    },
    subHeadingContainer: {
        textAlign:"center"
    },
    subHeading: {
        paddingBottom: "10px"
    },
    skillRatingAvatar: {
        margin: "auto",
        cursor: 'pointer'
    },
    skillRatingAvatarSelected: {
        margin: "auto",
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main
    },
    skillRatingAvatarContainer: {
        margin: "10px",
    },
    skillRatingTitle: {
        textTransform: "none",
        marginTop: 5,
        fontSize: "small"
    },
    saveButtonContainer: {
        padding: "50px"
    },
    avatar: {
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.main
    },
    skillRatingTitleHero: {
        fontWeight: "bold",
        color:  theme.palette.primary.main
    },
    borderLeftAndRight: {
        borderLeft: "1px",
        borderRight: "1px"
    }
}))

function valuetext(value) {
    return `${value}`;
}

const marks = [
    {value: 1, label: '1',},
    {value: 2, label: '2',},
    {value: 3, label: '3',},
    {value: 4, label: '4',},
    {value: 5, label: '5',},
    {value: 6, label: '6',},
    {value: 7, label: '7',},
    {value: 8, label: '8',}
];

function getSkillRatingDescription(skillRating){
    switch (skillRating) {
        case 1:
            return 'Can complete narrow and limited tasks requiring basic skills with direct supervision or guidance.'
        case 2:
            return 'Can complete routine technical tasks requiring basic skills with general supervision or guidance.'
        case 3:
            return 'Can process less structured but well-defined technical tasks, even if they are not routine, with occasional general supervision or guidance.'
        case 4:
            return 'Can complete some specialised and complex tasks. Can use knowledge of theory and practical experience to choose principles and processes. May or may not have overall supervision. No direct guidance needed for these tasks.'
        case 5:
            return 'Can complete many broad, complex tasks. Can use knowledge of theory and practical experience to choose principles and processes for technical tasks without supervision or guidance.'
        case 6:
            return 'Master of completing broad, complex tasks in several sub-specialties of the skill. Can use knowledge of theory and practical experience in all of those sub-specialties.'
        case 7:
            return 'Can complete new and complex tasks, including choosing the best processes for particular situations. Can use advanced specialised knowledge or processes.'
        case 8:
            return 'Can develop innovative new solutions or research using a wide range of scientific and creative strategies.'
    }
    return ''
}

export default function RatingStep({survey, segment, response, saveResponse, getNextUrl}) {

    const classes = useStyles();

    const [isLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [localSkills, setLocalSkills] = useState([])


    const navigate = useNavigate();

    useEffect(() => {

        const skills = [];
        segment.skillGroupIds.forEach(skillGroupId => {

            Object.entries(survey.skillGroups[skillGroupId]).forEach(([skillId, skill]) => {
                if (!segment.onlySelected || response.skillGroups[skillGroupId][skillId].isSelected) {
                    skill.id = skillId
                    skill.skillGroupId = skillGroupId
                    skill.selfRating = response.skillGroups[skillGroupId][skillId].selfRating ? response.skillGroups[skillGroupId][skillId].selfRating : 1
                    skills.push(skill)
                }
            })

        })
        setLocalSkills(skills)

    },[segment, survey, response]);

    const rateSkill = (index, rating) => {
        let tempSkills = localSkills.slice()
        tempSkills[index].selfRating = rating;
        setLocalSkills(tempSkills)
    }

    const buildSkillsList = () => {
        return localSkills.map((skill, index) => {
            return skillRatingJsx(skill, index, skill.skillGroupId)
        })
    }

    const skillRatingJsx  = (skill, index) => {
        if (!skill.selfRating) {
            skill.selfRating = 1
        }
        return (
            <Grid item lg={6} key={'skill-id-' + skill.id + '-index-' + index}>
                <Tooltip title={skill.description ? skill.description : ""} aria-label="description" arrow placement="top">
                    <Paper className={classes.skillItem} elevation={3} >
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={12} className={classes.subHeadingContainer}>
                                <Typography variant="h6">{skill.title}</Typography>
                                <Divider/>
                            </Grid>
                            <Slider
                                defaultValue={skill.selfRating ? skill.selfRating : 1}
                                getAriaValueText={valuetext}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                step={1}
                                min={1}
                                max={8}
                                onChange={(event, newValue) => rateSkill(index, newValue)}
                                marks={marks}
                                style={{marginTop: 20}}
                            />
                            <Grid item xs={12} className={classes.skillRatingAvatarContainer}>
                                <Avatar className={classes.skillRatingAvatarSelected}>{skill.selfRating}</Avatar>
                                <Typography className={classes.skillRatingTitle}>{getSkillRatingDescription(skill.selfRating)}</Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Tooltip>
            </Grid>
        )
    }

    const save = async () => {
        setIsSaving(true)
        const tempResponse = Object.assign({}, response)

        localSkills.forEach(skill => {
            tempResponse.skillGroups[skill.skillGroupId][skill.id].selfRating = skill.selfRating
        })

        try {
            await saveResponse(tempResponse);
            navigate(getNextUrl())
        } catch (e) {
            console.error(e)
            setIsSaving(false)
        }
    }

    return (
        <React.Fragment>
            {isLoading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <CircularProgress/>&nbsp;
                    <div>
                        <Typography variant="subtitle1">Loading your skills</Typography>
                    </div>
                </Grid>
                :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography className={classes.subHeading} variant="h4">{segment.subtitle}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center">
                                {buildSkillsList()}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        {
                            isSaving ?
                                <CircularProgress/> :
                                <Button size="large" variant="contained" color="primary" onClick={save}>Next →</Button>
                        }
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}
