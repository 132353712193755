import React, {useEffect, useState} from 'react';
import FullScreenLoading from "../FullScreenLoading";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {authenticatePractera, generateResponse, getJwt} from "../../service/backendApi";

export default function Practera() {

    const location = useLocation()
    const {tenantId, surveyId} = useParams();
    const navigate = useNavigate();

    const [isError, setIsError] = useState(false)

    useEffect(() => {
            const params = new URLSearchParams(location.search)
            const practeraJwt = params.get('jwt');
            if (practeraJwt) {
                authenticatePractera(tenantId, surveyId, practeraJwt).then(() => {
                    return generateResponse(tenantId, surveyId)
                }).then((response) => {
                    navigate('/survey/' + tenantId + '/' + surveyId)
                }).catch(error => {
                    console.error(error)
                    setIsError(true)
                })
            } else if (getJwt(surveyId)) {
                navigate('/survey/' + tenantId + '/' + surveyId)
            } else {
                console.error('No jwt parameter or existing jwt')
                setIsError(true)
            }


    }, [location.search, navigate, tenantId, surveyId])



    return (
        <React.Fragment>
            {isError ? <div>Error with auth</div> : <FullScreenLoading/>}
        </React.Fragment>
    );
}
