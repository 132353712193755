import axios from "axios";

const BASE_URL = 'https://hczom1xb31.execute-api.us-east-1.amazonaws.com/dev'

export const getJwt = (surveyId) => {
    return localStorage.getItem(surveyId + '-jwt');
}

export const authenticate = async (tenantId, surveyId) => {
    const body = {
        tenantId,
        surveyId
    }

    return axios.post(BASE_URL + '/auth', body)
        .then(data => {
            console.log(data)
            localStorage.setItem(surveyId + '-jwt', data.data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}

export const authenticatePractera = async (tenantId, surveyId, practeraJwt) => {
    const body = {
        tenantId,
        surveyId
    }

    return axios.post(BASE_URL + '/auth/practera', body, {
            headers: {
                Authorization: "Bearer " + practeraJwt
            }
        })
        .then(data => {
            console.log(data)
            localStorage.setItem(surveyId + '-jwt', data.data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}

export const generateResponse = async (tenantId, surveyId) => {
    const body = {
        tenantId,
        surveyId
    }

    return axios.post(BASE_URL + '/response', body, {
            headers: {
                Authorization: "Bearer " + getJwt(surveyId)
            }
        })
        .then(data => {
            console.log(data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}

export const fetchResponse = async (tenantId, surveyId) => {
    return axios.get(BASE_URL + '/response', {
            headers: {
                Authorization: "Bearer " + getJwt(surveyId)
            }
        })
        .then(data => {
            console.log(data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })}

export const saveResponse = async (tenantId, surveyId, response) => {
    console.log('Saving response', response)

    return axios.post(BASE_URL + '/response', response, {
            headers: {
                Authorization: "Bearer " + getJwt(surveyId)
            }
        })
        .then(data => {
            console.log('response', data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}

export const fetchSurvey = async (tenantId, surveyId) => {
    return axios.get(BASE_URL + '/survey/' + surveyId)
        .then(data => {
            console.log('survey', data)
            return data.data;
        })
        .catch(error => {
            console.error("Error in Axios call to 21skills backend", error)
        })
}


