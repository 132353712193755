import React from 'react';
import logo from '../static/skillpaca_logo.png';
import Grid from "@material-ui/core/Grid";

function Logo({logoUrl}) {
    return (
        <Grid item xs={10} style={{textAlign: "center", paddingTop: "20px", paddingBottom: "20px"}}>
            <img src={logoUrl ? logoUrl : logo} alt="Logo" height="100px"/>
        </Grid>
    )
}

export default Logo;
