import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Divider, Hidden, Tooltip} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    skillItem: {
        padding: '20px',
        margin: '20px',
        textTransform: "capitalize",
        textAlign: "center"
    },
    loadingContainer: {
        paddingTop: "50px"
    },
    subHeadingContainer: {
        textAlign:"center"
    },
    subHeading: {
        paddingBottom: "10px"
    },
    skillRatingAvatar: {
        margin: "auto",
        fontSize: "30px",
        filter: "grayscale(100%)"
    },
    skillRatingAvatarSelected: {
        margin: "auto",
        fontSize: "40px",
    },
    skillRatingAvatarContainer: {
        margin: "10px",
        cursor: 'pointer'
    },
    skillRatingTitle: {
        fontSize: "small"
    },
    skillRatingTitleSelect: {
        fontSize: "small",
        fontWeight: "bold"
    },
    saveButtonContainer: {
        padding: "50px"
    },
    skillRatingTitleHero: {
        fontWeight: "bold"
    },
    borderLeftAndRight: {
        borderLeft: "1px",
        borderRight: "1px"
    }
}))


export default function JoyStep({survey, segment, response, saveResponse, getNextUrl}) {

    const classes = useStyles();

    const [isLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [localSkills, setLocalSkills] = useState([])

    const navigate = useNavigate();

    useEffect(() => {

        const tempResponse = Object.assign(response, {})

        const skills = [];
        segment.skillGroupIds.forEach(skillGroupId => {

            Object.entries(survey.skillGroups[skillGroupId]).forEach(([skillId, skill]) => {
                if (!segment.onlySelected || tempResponse.skillGroups[skillGroupId][skillId].isSelected) {

                    if (!tempResponse.skillGroups) {
                        tempResponse.skillGroups = {};
                    }
                    if (!tempResponse.skillGroups[skillGroupId]) {
                        tempResponse.skillGroups[skillGroupId] = {};
                    }
                    if (!tempResponse.skillGroups[skillGroupId][skillId]) {
                        tempResponse.skillGroups[skillGroupId][skillId] = {}
                    }

                    skill.id = skillId
                    skill.skillGroupId = skillGroupId
                    skill.joyRating = tempResponse.skillGroups[skillGroupId][skillId].joyRating ? tempResponse.skillGroups[skillGroupId][skillId].joyRating : 2
                    skills.push(skill)
                }
            })

        })
        setLocalSkills(skills)

    },[segment, survey, response]);

    const rateSkill = (index, rating) => {
        let tempSkills = localSkills.slice()
        tempSkills[index].joyRating = rating;
        setLocalSkills(tempSkills)
    }

    const buildSkillsList = () => {
        return localSkills.map((skill, index) => {
            return skillRatingJsx(skill, index)
        })
    }

    const skillRatingJsx  = (skill, index, ) => {
        if (!skill.joyRating) {
            skill.joyRating = 2
        }
        return (
            <Grid item xs={12} sm={12} lg={6} key={'skill-id-' + skill.id + '-index-' + index}>
                <Tooltip title={skill.description ? skill.description : ""} aria-label="description" arrow placement="top">
                    <Paper className={classes.skillItem} elevation={3} >
                        <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={12} className={classes.subHeadingContainer}>
                                <Typography variant="h6">{skill.title}</Typography>
                                <Divider/>
                            </Grid>
                            <Grid onClick={() => rateSkill(index, 1)}  item xs={3} className={classes.skillRatingAvatarContainer}>
                                <div className={skill.joyRating === 1 ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                    <span role="img" aria-label="frustrated">😩</span>
                                </div>
                                <Hidden smDown><Typography className={skill.joyRating === 1 ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >Hate it</Typography></Hidden>
                            </Grid>
                            <Grid onClick={() => rateSkill(index, 2)} item xs={3} className={classes.skillRatingAvatarContainer}>
                                <div className={skill.joyRating === 2 ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                    <span role="img" aria-label="content">🙂</span>
                                </div>
                                <Hidden smDown><Typography className={skill.joyRating === 2 ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >Don't Mind</Typography></Hidden>
                            </Grid>
                            <Grid onClick={() => rateSkill(index, 3)} item xs={3} className={classes.skillRatingAvatarContainer}>
                                <div className={skill.joyRating === 3 ? classes.skillRatingAvatarSelected : classes.skillRatingAvatar}>
                                    <span role="img" aria-label="love">😍</span>
                                </div>
                                <Hidden smDown><Typography className={skill.joyRating === 3 ? classes.skillRatingTitleSelect : classes.skillRatingTitle} >Love it</Typography></Hidden>
                            </Grid>
                        </Grid>
                    </Paper>
                </Tooltip>
            </Grid>
        )
    }

    const save = async () => {
        setIsSaving(true)
        const tempResponse = Object.assign({}, response)

        localSkills.forEach(skill => {
            tempResponse.skillGroups[skill.skillGroupId][skill.id].joyRating = skill.joyRating
            tempResponse.skillGroups[skill.skillGroupId][skill.id].title = skill.title
        })

        try {
            await saveResponse(tempResponse);
            navigate(getNextUrl())
        } catch (e) {
            console.error(e)
            setIsSaving(false)
        }
    }

    return (
        <React.Fragment>
            {isLoading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <CircularProgress/>&nbsp;
                    <div>
                        <Typography variant="subtitle1">Loading your skills</Typography>
                    </div>
                </Grid>
                :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography className={classes.subHeading} variant="h4">{segment.subtitle}</Typography>

                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={4} md={1}>
                                    <div className={classes.skillRatingAvatarSelected}><span role="img" aria-label="frustrated">😩</span></div>
                                    <Typography className={classes.skillRatingTitleHero} variant="subtitle1">Hate it.</Typography>
                                </Grid>
                                <Grid item xs={4} md={1} className={classes.borderLeftAndRight}>
                                    <div className={classes.skillRatingAvatarSelected}><span role="img" aria-label="content">🙂</span></div>
                                    <Typography className={classes.skillRatingTitleHero} variant="subtitle1">Don't mind.</Typography>
                                </Grid>
                                <Grid item xs={4} md={1} >
                                    <div className={classes.skillRatingAvatarSelected}><span role="img" aria-label="love">😍</span></div>
                                    <Typography className={classes.skillRatingTitleHero} variant="subtitle1">Love it.</Typography>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center">
                                {buildSkillsList()}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        {
                            isSaving ?
                                <CircularProgress/> :
                                <Button size="large" variant="contained" color="primary" onClick={save}>Next →</Button>
                        }
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}
