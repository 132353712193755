import React, {useEffect, useState} from 'react';
import {
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Collapse,
    Divider,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
    Tooltip
} from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {useNavigate} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import NewReleasesIcon from '@material-ui/icons/NewReleases';


// border: solid;
// border-width: 2px;
// border-color: #335eea;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    logo: {
        textAlign: "center",
        paddingTop: "25px"
    },
    loadingContainer: {
        paddingTop: "50px"
    },
    subHeadingContainer: {
        textAlign:"center"
    },
    subHeading: {
        paddingBottom: "10px"
    },
    skillRatingTitle: {
        color: theme.palette.primary.main,
        fontSize: "small"
    },
    skillItemHeaderDiv: {
        padding: 20,
        cursor: 'pointer'
    },
    skillItemBodyDiv: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        textTransform: "none"
    },
    skillItem: {
        margin: '20px',
        textTransform: "capitalize",
        textAlign: "center"
    },
    saveButtonContainer: {
        padding: "50px"
    },
    avatar: {
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.main
    },
    skillRatingTitleHero: {
        fontWeight: "bold",
        color:  theme.palette.primary.main
    },
    borderLeftAndRight: {
        borderLeft: "1px",
        borderRight: "1px"
    },
    emptyList: {
        textAlign: 'center',
        border: 'solid',
        borderRadius: 30,
        padding: 20,
        borderColor: '#909a9b',
        margin: 20,
        position: 'relative'
    },
    emptyListIcon: {
        fontSize: '3em',
    },
    iWantToHelp: {
        paddingTop: '20px'
    }
}))


export default function AddToTeamStep({survey, segment, response, saveResponse, getNextUrl}) {

    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [localSkills, setLocalSkills] = useState([])

    const navigate = useNavigate();

    useEffect(() => {
        const skills = []

        segment.skillGroupIds.forEach(skillGroupId => {

            Object.entries(survey.skillGroups[skillGroupId]).forEach(([skillId, skill]) => {

                if (!segment.onlySelected || response.skillGroups[skillGroupId][skillId].isSelected) {
                    skill.id = skillId
                    skill.skillGroupId = skillGroupId
                    skill.isAddToTeam = response.skillGroups[skillGroupId][skillId].isAddToTeam ? response.skillGroups[skillGroupId][skillId].isAddToTeam : false
                    skill.weDontHaveTime = response.skillGroups[skillGroupId][skillId].weDontHaveTime ? response.skillGroups[skillGroupId][skillId].weDontHaveTime : false
                    skill.notGoodEnough = response.skillGroups[skillGroupId][skillId].notGoodEnough ? response.skillGroups[skillGroupId][skillId].notGoodEnough : false
                    skill.weShouldDoMore = response.skillGroups[skillGroupId][skillId].weShouldDoMore ? response.skillGroups[skillGroupId][skillId].weShouldDoMore : false
                    skill.iWantToHelp = response.skillGroups[skillGroupId][skillId].iWantToHelp ? response.skillGroups[skillGroupId][skillId].iWantToHelp : false
                    skill.otherReason = response.skillGroups[skillGroupId][skillId].otherReason ? response.skillGroups[skillGroupId][skillId].otherReason : ""
                    skills.push(skill)
                }
            })

        })

        setLocalSkills(skills)
        setIsLoading(false)
    },[survey, segment, response]);

    const addToTeam = (index) => {
        let tempSkills = localSkills.slice()
        tempSkills[index].isAddToTeam = !tempSkills[index].isAddToTeam
        setLocalSkills(tempSkills)
    }

    const checkSkillBox = (event, index) => {
        let tempSkills = localSkills.slice()
        tempSkills[index][event.target.name] = event.target.checked
        setLocalSkills(tempSkills)
    }

    const updateOtherReasonText = (event, index) => {
        let tempSkills = localSkills.slice()
        tempSkills[index]["otherReason"] = event.target.value
        setLocalSkills(tempSkills)
    }

    function buildSkillsList() {
        let skillsJsx = []

        skillsJsx = localSkills.map((skill, index) => {
            return skillRatingJsx(skill, index)
        })

        if (skillsJsx.length) {
            return skillsJsx;
        }

        return (
            <div className={classes.emptyList}>
                <NewReleasesIcon className={classes.emptyListIcon}/>
                <Typography variant="subtitle1">You already know all of the listed skills. You can skip this step!</Typography>
            </div>
        )

    }

    const skillRatingJsx  = (skill, index) => {
            return (
                <Grid key={'skill-id-' + skill.id + '-index-' + index} item lg={6}>
                    <Tooltip title={skill.description ? skill.description : ""} aria-label="description" arrow placement="top">
                        <Paper elevation={3} className={classes.skillItem}>
                            <div onClick={() => addToTeam(index)}>
                                <div className={classes.skillItemHeaderDiv}>
                                    {skill.isAddToTeam ? <Typography variant="h6">{skill.title} <Chip color="primary" label="Help wanted" onClick={()=>{}}/></Typography> : (<Typography variant="h6">{skill.title} <Chip color="default" label="We're good" onClick={()=>{}}/></Typography>)}
                                </div>
                            </div>
                            <Collapse in={skill.isAddToTeam} collapsedHeight={0}>
                                <div className={classes.skillItemBodyDiv}>
                                    <Divider/>
                                    {/*<Divider/>*/}
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox checked={skill["weDontHaveTime"]}
                                                               color="primary"
                                                               onChange={(event) => checkSkillBox(event, index)}
                                                               name="weDontHaveTime"/>}
                                            label="We don't have enough time to do this"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={skill["notGoodEnough"]}
                                                               color="primary"
                                                               onChange={(event) => checkSkillBox(event, index)}
                                                               name="notGoodEnough"/>}
                                            label="We're not good enough at this"
                                        />
                                    </FormGroup>
                                    <TextField
                                        fullWidth
                                        label="Any other reason?"
                                        size="medium"
                                        onChange={(event) => updateOtherReasonText(event, index)}
                                        value={skill["otherReason"]}
                                    />
                                    <FormGroup className={classes.iWantToHelp}>
                                        <FormControlLabel
                                            control={<Switch checked={skill["iWantToHelp"]}
                                                               color="primary"
                                                               onChange={(event) => checkSkillBox(event, index)} name="iWantToHelp"
                                            />}
                                            label="I personally want to help the team with this"
                                        />
                                    </FormGroup>
                                </div>
                            </Collapse>
                        </Paper>
                    </Tooltip>
                </Grid>
            )
    }

    const save = async () => {
        setIsSaving(true)
        const tempResponse = Object.assign({}, response)

        localSkills.forEach(skill => {
            if (tempResponse.skillGroups[skill.skillGroupId] && tempResponse.skillGroups[skill.skillGroupId][skill.id]) {
                tempResponse.skillGroups[skill.skillGroupId][skill.id].isAddToTeam = skill.isAddToTeam
                tempResponse.skillGroups[skill.skillGroupId][skill.id].weDontHaveTime = skill.weDontHaveTime
                tempResponse.skillGroups[skill.skillGroupId][skill.id].notGoodEnough = skill.notGoodEnough
                tempResponse.skillGroups[skill.skillGroupId][skill.id].weShouldDoMore = skill.weShouldDoMore
                tempResponse.skillGroups[skill.skillGroupId][skill.id].iWantToHelp = skill.iWantToHelp
                tempResponse.skillGroups[skill.skillGroupId][skill.id].otherReason = skill.otherReason
            }
        })

        try {
            await saveResponse(tempResponse);
            navigate(getNextUrl())
        } catch (e) {
            console.error(e)
            setIsSaving(false)
        }
    }

    return (
        <React.Fragment>
            {isLoading ?
                <Grid container className={classes.loadingContainer} direction="row" justify="center" alignItems="center">
                    <div>
                        <CircularProgress/>
                    </div>
                </Grid>
                :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} className={classes.subHeadingContainer}>
                            <Typography className={classes.subHeading} variant="h4">{segment.subtitle}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="center" alignItems="center">
                                {buildSkillsList()}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container className={classes.saveButtonContainer} direction="row" justify="center" alignItems="center">
                        {
                            isSaving ?
                                <CircularProgress/> :
                                <Button size="large" variant="contained" color="primary" onClick={save}>Next →</Button>
                        }
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>

    );
}
