import React, {useEffect, useState} from "react";
import {authenticate, fetchResponse, fetchSurvey, generateResponse, getJwt, saveResponse} from "../service/backendApi";
import {Container} from "@material-ui/core";
import {useNavigate, useParams} from "react-router-dom";
import SelectSkillsSegment from "./segments/SelectSkillsSegment";
import Grid from "@material-ui/core/Grid";
import Logo from "./Logo";
import SurveyStepper from "./SurveyStepper";
import JoyStep from "./segments/JoyStep";
import RatingStep from "./segments/RatingStep";
import LearnStep from "./segments/LearnStep";
import ExperienceStep from "./segments/ExperienceStep";
import AddToTeamStep from "./segments/AddToTeamStep";
import CurrentlyUsing from "./segments/CurrentlyUsing";
import FullScreenLoading from "./FullScreenLoading";
import SegmentNotFound from "./segments/SegmentNotFound";
import {v4 as uuidv4} from 'uuid';
import SelectJoyAndSelfRatingSegment from "./segments/SelectJoyAndSelfRatingSegment";
import SelectAndJoyRating from "./segments/SelectAndJoyRatingSegment";
import LevelsRubricSegment from "./segments/LevelsRubricSegment";

const skills = [
    {
        "competency": "Technology Skills",
        "description": "Analytical or scientific software, e.g. IBM SPSS Statistics",
        "isCritical": false,
        "title": "Analytical or scientific software"
    },
    {
        "competency": "Technology Skills",
        "description": "Customer relationship management CRM software, e.g. Salesforce software",
        "isCritical": false,
        "title": "Customer relationship management CRM software"
    },
    {
        "competency": "Technology Skills",
        "description": "Enterprise resource planning ERP software, e.g. Microsoft Dynamics",
        "isCritical": false,
        "title": "Enterprise resource planning ERP software"
    },
    {
        "competency": "Technology Skills",
        "description": "Graphics or photo imaging software, e.g. JamBoard",
        "isCritical": false,
        "title": "Graphics or photo imaging software"
    },
    {
        "competency": "Technology Skills",
        "description": "Operating system software, e.g. Apple macOS",
        "isCritical": false,
        "title": "Operating system software"
    },
    {
        "competency": "Technology Skills",
        "description": "Project management software, e.g. Microsoft Project",
        "isCritical": false,
        "title": "Project management software"
    },
    {
        "competency": "Technology Skills",
        "description": "Project management software, e.g. HubSpot software",
        "isCritical": false,
        "title": "Project management software"
    }
]

const skillObject = {};
skills.forEach(skill => {
    const uuid = uuidv4()
    skillObject[uuid] = skill
})

// console.log(JSON.stringify(skillObject, null, 2))

export default function SurveyContainer() {
    const {tenantId, surveyId, segmentIndex} = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [survey, setSurvey] = useState(undefined);
    const [steps, setSteps] = useState([]);
    const [response, setResponse] = useState({})

    useEffect(() => {
        window.scrollTo(0,0)
        setIsLoading(true)
        const jwt = getJwt(surveyId);
        if (!jwt) {
            authenticate(tenantId, surveyId).then(data => {
                generateResponse(tenantId, surveyId).then(response => {
                    navigate('/survey/' + tenantId + '/' + surveyId + '/' + 0)
                })
            })
        } else {
            if (!segmentIndex) {
                navigate('/survey/' + tenantId + '/' + surveyId + '/' + 0)
            }

            if (!survey || !response) {
                const fetchResponsePromise = fetchResponse(tenantId, surveyId)
                const fetchSurveyPromise = fetchSurvey(tenantId, surveyId);
                Promise.all([fetchResponsePromise, fetchSurveyPromise]).then(([responseData, surveyData]) => {
                    setResponse(responseData)
                    setSurvey(surveyData)
                    // calculateSteps(surveyData)
                    setSteps(surveyData.structure)
                    setIsLoading(false)

                    // console.log(JSON.stringify(surveyData, null, 2))
                }).catch(error => {
                    console.error(error)
                })
            } else {
                setIsLoading(false)
            }
        }
    }, [tenantId, surveyId, segmentIndex, navigate, response, survey])

    const saveResponseContainer = async (response) => {
        if (parseInt(segmentIndex) + 1 >= steps.length) {
            response.status = "COMPLETED"
        } else {
            response.status = "IN_PROGRESS"
        }
        response = await saveResponse(tenantId, surveyId, response)
        sendSegmentIdentify(response.id)
        setResponse(response)
    }

    const getNextUrl = () => {
        if (parseInt(segmentIndex) + 1 < steps.length) {
            return '/survey/' + tenantId + '/' + surveyId + '/' + (parseInt(segmentIndex) + 1)
        } else {
            if (survey.redirect) {
                window.location = survey.redirect;
            } else {
                return '/complete/' + tenantId + '/' + surveyId
            }
        }
    }

    const sendSegmentIdentify = (id) => {
        window.analytics.identify(id, {});
    }

    const renderSegment = (steps, segmentIndex) => {
        const step = steps[segmentIndex];
        const segment = survey.structure[segmentIndex];

        switch (step.type) {
            case 'selectSkills':
                return <SelectSkillsSegment survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl}/>
            case 'currentlyUsing':
                return <CurrentlyUsing survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl}/>
            case 'experience':
                return <ExperienceStep survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            case 'rating':
                return <RatingStep survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            case 'joy':
                return <JoyStep survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            case 'learn':
                return <LearnStep survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            case 'addToTeam':
                return <AddToTeamStep survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            case 'selectAndJoyRating':
                return <SelectAndJoyRating survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            case 'selectJoyAndSelfRating':
                return <SelectJoyAndSelfRatingSegment survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            case 'levelsRubric':
                return <LevelsRubricSegment survey={survey} segment={segment} response={response} saveResponse={saveResponseContainer} getNextUrl={getNextUrl} />
            default:
                return <SegmentNotFound getNextUrl={getNextUrl}/>
        }
    }

    return (
        <Container>
            {isLoading ? <FullScreenLoading/> :
                <React.Fragment>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Logo logoUrl={survey.logoUrl} />
                    </Grid>
                    <SurveyStepper steps={steps} activeStep={segmentIndex}/>
                    {renderSegment(steps, segmentIndex)}
                </React.Fragment>
            }
        </Container>
    )
}
